<template>
  <div class="domain-detail-ns">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <base-alert
        v-else-if="!isLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="domain-detail-ns__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <page-block v-else :title="$t('title')">
        <main-card>
          <p class="standart-text">
            {{ $t('desc') }}
          </p>
          <div class="domain-detail-ns__form">
            <div
              v-if="provider && provider.ns && provider.ns.length"
              class="domain-detail-ns__useproviderns"
            >
              <div class="domain-detail-ns__useproviderns-text standart-text">
                {{ $t('useproviderns') }}
              </div>
              <plain-button color="primary" @click="useProviderNs">
                {{ provider.v }}
              </plain-button>
            </div>
            <div
              v-for="(item, idx) in formDataArr"
              :key="idx"
              class="domain-detail-ns__field"
              :class="{ ['domain-detail-ns__field--deletable']: !requiredIndex.includes(idx) }"
            >
              <base-input
                v-model="formDataArr[idx]"
                :name="fieldKey + idx"
                :placeholder="$t('field.placeholder')"
                :hint="$t('field.hint')"
                class="domain-detail-ns__input"
              >
                {{ (fieldKey + (idx + +1)) | uppercase }}
                <plain-button
                  v-if="!requiredIndex.includes(idx)"
                  slot="fieldAfter"
                  color="del"
                  icon="delete"
                  class="domain-detail-ns__del"
                  tabindex="-1"
                  @click="deleteField(idx)"
                />
              </base-input>
            </div>
            <plain-button color="primary" class="domain-detail-ns__add" @click="addField">
              {{ $t('add') }}
            </plain-button>
          </div>
          <base-button
            slot="footerEnd"
            :loading="isSending"
            :disabled="!toolNs || !hasChanges"
            @click="save"
          >
            {{ $t('save') }}
          </base-button>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import { DomainTariff } from '@/models/BillMgr/DomainTariff';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import Vue from 'vue';
import { isEqual } from 'lodash';
import askUnsavedPageLeave from '@/mixins/askUnsavedPageLeave';
export default {
  name: 'DomainDetailNs',
  components: { BaseInput },
  filters: {
    uppercase: val => val.toUpperCase(),
  },
  mixins: [billMgrToolActions, askUnsavedPageLeave],
  props: {
    tariff: {
      type: DomainTariff,
      required: true,
      validator: obj => obj instanceof DomainTariff,
    },
  },
  data() {
    return {
      isLoading: false,
      isSending: false,
      isServerError: false,
      maxIndex: 3,
      requiredIndex: [0, 1],
      fieldKey: 'ns',
      formDataArr: [],
      model: null,
      slist: null,
    };
  },
  computed: {
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsDomains.tools;
    },
    toolNs() {
      if (!this.tools || !this.tools.ns || !this.tools.ns.isEnable(this.tariff)) return null;
      return this.tools.ns;
    },
    provider() {
      if (!this.model || !this.slist || !this.slist.provider || !this.model.provider) return null;
      const list = this.slist.provider;
      const value = this.model.provider;
      const provider = list.find(item => item.k === value);
      if (!~provider) return null;
      return provider;
    },
    immediateKeys() {
      let arr = [];
      for (let i = 0; i <= this.maxIndex; i++) {
        arr.push(this.fieldKey + i);
      }
      arr.push(this.fieldKey + '_additional');
      return arr;
    },
    formData() {
      if (!this.model) return null;
      const hasValuesArr = this.formDataArr.filter(item => !!item);
      const reduced = hasValuesArr.reduce((acc, item, idx, arr) => {
        if (idx <= this.maxIndex) acc[this.fieldKey + idx] = item;
        else {
          if (!acc[`${this.fieldKey}_additional`]) acc[`${this.fieldKey}_additional`] = '';
          acc[`${this.fieldKey}_additional`] += item;
          if (idx < arr.length - 1) acc[`${this.fieldKey}_additional`] += ' ';
        }
        return acc;
      }, {});
      this.immediateKeys.forEach(k => {
        if (!reduced[k]) reduced[k] = '';
      });
      return { ...this.model, ...reduced };
    },
    hasChanges() {
      if (!this.model || !this.formData) return false;
      return !isEqual(this.model, this.formData);
    },
  },
  watch: {
    toolNs(tool) {
      if (tool && tool.isEnable(this.tariff)) this.fetch();
    },
  },
  created() {
    if (this.toolNs && this.toolNs.isEnable(this.tariff)) this.fetch();
  },
  beforeRouteLeave(_to, _from, next) {
    if (!this.hasChanges) next();
    else {
      this.askUnsavedPageLeave()
        .then(() => next())
        .catch(() => {})
        .finally(() => this.$modals.close());
    }
  },
  methods: {
    fetch(id = this.tariff.id) {
      const params = {
        elid: id,
        func: this.toolNs.func,
      };
      this.fetchBillMgrAction(params)
        .then(data => {
          this.model = { ...data.model };
          this.slist = { ...data.slist };
          this.initFormData();
        })
        .catch(e => {
          if (e.status && e.status === 520) this.isServerError = true;
        });
    },
    initFormData() {
      for (let i = 0; i <= this.maxIndex; i++) {
        this.formDataArr.push('');
        if (this.model[this.fieldKey + i]) this.formDataArr[i] = this.model[this.fieldKey + i];
      }
      if (this.model[`${this.fieldKey}_additional`]) {
        const splitted = this.model[`${this.fieldKey}_additional`].split(/\n|\s/);
        splitted.forEach(val => {
          this.formDataArr.push(val.trim());
        });
      }
    },
    updateFormData(model = this.model) {
      this.fieldKeys = this.formData;
      this.fieldKeys.forEach(k => {
        if (model[k]) this.formData[k] = model[k];
      });
    },
    useProviderNs() {
      if (this.formDataArr.length > 2) {
        this.formDataArr = this.formDataArr.splice(1, 2);
      }
      this.provider.ns.forEach((item, idx) => {
        Vue.set(this.formDataArr, idx, item.v);
      });
    },
    addField() {
      this.formDataArr.push('');
    },
    deleteField(idx) {
      if (!this.requiredIndex.includes(idx)) {
        this.formDataArr.splice(idx, 1);
      }
    },
    save() {
      this.isSending = true;
      const params = {
        ...this.formData,
        func: this.toolNs.func,
        sok: 'ok',
      };
      this.sendBillMgrAction(params)
        .then(data => {
          if (data.ok) {
            this.$modals.open({
              name: 'NsResult',
              title: this.$t('success.title'),
              text: this.$t('success.text'),
              size: 'small',
              footer: {
                centered: true,
                confirm: {
                  props: { title: this.$t('ok') },
                  on: { click: () => this.$modals.close() },
                },
              },
            });
            this.reset();
            this.fetch();
          }
        })
        .finally(() => (this.isSending = false));
    },
    reset() {
      this.formDataArr = [];
      this.model = null;
      this.slist = null;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Серверы имён (NS)",
    "desc": "Серверы имён необходимы для определения IP-адреса, где находится веб-сайт, по его доменному имени. Для одного доменного имени указывается как минимум два сервера имён: NS1 и NS2.",
    "field": {
      "placeholder": "ns.example.com",
      "hint": "Фомат записи \"ns.example.com\" или \"ns.example.com/1.1.1.1\" или \"ns.example.com/1.1.1.1,2.2.2.2\""
    },
    "add": "Добавить ещё",
    "useproviderns": "Использовать",
    "success": {
      "title": "Успешно",
      "text": "Серверы имён успешно сохранены"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.domain-detail-ns {
  &__field {
    +breakpoint(sm-and-up) {
      max-width: 31rem;
    }

    &--deletable {
      +breakpoint(sm-and-up) {
        max-width: 33rem;
      }
    }

    & + & {
      margin-top: 1.5rem;
    }
  }
  &__add {
    margin-top: 1rem;
  }
  &__del {
    margin-left: 0.5rem;
  }
  &__useproviderns {
    flexy(flex-start, baseline, wrap);
    margin-bottom: 1.5rem;

    &-text {
      margin-right: 0.5rem;
    }
  }
}
</style>
